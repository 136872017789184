<template>
  <div >
    <head-layout  :head-title="$t('cip.dc.FullInter.title.FullInterfaceAcquisition')" :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout>

        <div class="headers" style="display: flex;">
          <el-input :placeholder="$t('cip.dc.FullInter.field.InterfaceName')" v-model="queryList.param1" class="hinput" size="mini" style="width:200px" />
          <el-button-group style="margin-left: 10px;">


          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </el-button-group>
        </div>



        <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="handleSelectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-edit="handleEdit"
            @grid-romve="delRow"
            :searchColumns="searchColumns"
          >
          <template slot="requestMode" slot-scope="{row}" >
            {{row.requestMode == 1?'get':'post'}}
      </template>
          </grid-layout>








  </div>
</template>

<script>
import {
  totalCoList,
  totalCollectUp,
  totalCollectDel, deleteDataSource,
} from "@/api/dataAcquisition/index";
  import { mapGetters } from "vuex";
  import website from '@/config/website';
  import HeadLayout from "@/views/components/layout/head-layout"
  import GridLayout from "@/views/components/layout/grid-layout";
  import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
  import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
  import formLayout from '@/views/components/layout/form-layout'
  export default {
    components: {
      HeadLayout,
      GridLayout,
      dialogHeadBtn,
      formLayout
    },
    data() {
      return {
        gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },
      ],
        tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.dc.FullInter.field.InterfaceName'),
            prop: "confName",
            cell: true,
            width:200
          },
          {
            label: this.$t('cip.dc.FullInter.field.InterfaceAddress'),
            prop: "confApi",
            cell: true,
            width:400,
            overHidden:true
          },
          {
            label: this.$t('cip.dc.FullInter.field.RequestMethod'),
            prop: "requestMode",
            slot:true,
            cell: true,

          },
          {
            label: this.$t('cip.dc.FullInter.field.ReturnStatusCode'),
            prop: "resultCode",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.FullInter.field.ReturnStatusValue'),
            prop: "successIdentifier",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.FullInter.field.PrimaryKeyName'),
            prop: "sourcePrimaryKeyName",
            cell: true,

          },
          {
            label: this.$t('cip.dc.FullInter.field.ResultConfiguration'),
            prop: "tokenField",
            cell: true,
            width:200,
          },
          {
            label: this.$t('cip.dc.FullInter.field.InterfaceCode'),
            prop: "interfaceCode",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.FullInter.field.TopicConfiguration'),
            prop: "confTopic",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.FullInter.field.SystemSourceID'),
            prop: "sourceId",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.FullInter.field.ForeignKeyCode'),
            prop: "confKey",
            cell: true,
            width: 100
          },
          {
            label: this.$t('cip.dc.FullInter.field.CreationTime'),
            prop: "createTime",
            cell: true,
            width:200,
          },
          {
            label: this.$t('cip.dc.FullInter.field.ModificationTime'),
            prop: "updateTime",
            cell: true,
            width:200,
          },
          {
            label: this.$t('cip.dc.FullInter.field.InterfaceDescription'),
            prop: "operatorName",
            cell: true,
            width:200,
          },


        ],

      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      tableLoading:false,
        activeName: '1',
        multipleSelection:[],
        tableData: [],
        total:0,
        queryList:{
          query:{
            current:1,
            size:10,
          },
          param1:''
        },
        headBtnOptions: [{
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          type: "button",
          icon: ""
        }, {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: ""
        }],
        form: null,
        formLabelWidth: '120px',
        dialogFormVisible:false,
        loading:false,
        rules: {
          confName: [
            { required: true, message: this.$t("cip.dc.FullInter.field.InterfaceName"), trigger: 'change' }
          ],
          requestMode: [
            { required: true, message: this.$t("cip.dc.FullInter.field.RequestMethod"), trigger: 'change' }
          ],
          confApi: [
            { required: true, message: this.$t("cip.dc.FullInter.field.InterfaceAddress"), trigger: 'change' }
          ],

          sourcePrimaryKeyName: [
            { required: true, message: this.$t("cip.dc.FullInter.field.PrimaryKeyName"), trigger: 'change' }
          ],
          interfaceCode: [
            { required: true, message: this.$t("cip.dc.FullInter.field.InterfaceCode"), trigger: 'change' }
          ],
          sourceId: [
            { required: true, message: this.$t("cip.dc.FullInter.field.SystemSourceID"), trigger: 'change' }
          ],
          confTopic: [
            { required: true, message: this.$t("cip.dc.FullInter.field.TopicConfiguration"), trigger: 'change' }
          ],
          resultCode: [
            { required: true, message: this.$t("cip.dc.FullInter.field.ReturnStatusCode"), trigger: 'change' }
          ],
          successIdentifier: [
            { required: true, message: this.$t("cip.dc.FullInter.field.ReturnStatusValue"), trigger: 'change' }
          ],
          operatorName: [
            { required: true, message: this.$t("cip.dc.FullInter.field.InterfaceDescription"), trigger: 'change' }
          ],
          confdescribe: [
            { required: true, message: this.$t("cip.dc.FullInter.field.ResultConfiguration"), trigger: 'change' }
          ],
        }
      };
    },
    computed: {
      ...mapGetters(["userInfo", "permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.dept_add, false),
          viewBtn: this.vaildData(this.permission.dept_view, false),
          delBtn: this.vaildData(this.permission.dept_delete, false),
          editBtn: this.vaildData(this.permission.dept_edit, false)
        };
      },
    },
    methods: {
      rowRM(e){
       if(e.requestMode == "1"){
        return "get"
       }
       if(e.requestMode == "2"){
        return "post"
       }
       else{
        return e.requestMode
       }

      },
      onLoad(page, params = {}) {
        this.loading = true;
        totalCoList(this.queryList).then(res => {

          this.loading = false;

        this.tableData = res.data.data.dataList
        this.total = res.data.data.page.totalSize
        });
      },
      addFn(){
        this.$router.push({
          path: '/dataAcquisition/qInterfaceAcAdd',
          query: {
            type: 'add',
          }
        })
      },
      handleEdit(item){
        this.$router.push({
          path: '/dataAcquisition/qInterfaceAcAdd',
          query: {
            type: 'edit',
            id:item.id
          }
        })
      },

      delRow(e){
        let ids = []
        ids[0] = e.id

          this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), this.$t('cip.cmn.btn.delBtn'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: 'warning'
        }).then(() => {

          totalCollectDel(ids).then((res)=>{
            this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'));
            this.onLoad()
          })
        }).catch(() => {

        });
      },
      deleteFn(){
        if(this.multipleSelection.length == 0){
          this.$message.error(this.$t('cip.cmn.msg.warning.tipDetrmineSelectDel'))
          return
        }
        let ids = this.multipleSelection.map((item)=>{
          console.log(item,item.id)
          return item.id
        })
        this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), this.$t('cip.cmn.btn.delBtn'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: 'warning'
        }).then(() => {
          totalCollectDel(ids).then((res)=>{
            this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'));
            this.onLoad()
          })
        }).catch(() => {

        });

      },
      czFn(){
        this.queryList.param1 = '';
        this.onLoad()
      },
      handleSelectionChange(val) {
        console.log(val)
        this.multipleSelection = val;
      },

      handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      this.queryList.query.current = 1
      this.queryList.query.size = val.pageSize;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.current = val.currentPage;
      this.onLoad()
    },
    },
    created() {
      this.onLoad();
    },
    mounted() {
    },
  };
</script>
<style scoped>
.headers{
  background: #FFFFFF;
  padding: 14px 12px;
}
.headers ::v-deep .el-button{
  height: 28px;
}
</style>
<style>
.el-button+.el-button {
  margin-left: 0px;
}
.new-sino-input{
  width: 200px;
}
</style>
